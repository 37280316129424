<template>
  <v-dialog transition="scale-transition" max-width="500" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon v-if="isUpdate">mdi-information</v-icon>
        <v-icon v-else>mdi-account-plus</v-icon>
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-toolbar class="mb-1">
          <div class="text-h6">{{ name }}</div>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="user.firstName"
              :rules="[(v) => !!v || 'First name is required']"
              label="First Name"
              required
            />

            <v-text-field
              v-model="user.lastName"
              :rules="[(v) => !!v || 'Last name is required']"
              label="Last Name"
              required
            />

            <v-text-field
              v-model="user.username"
              :rules="emailRules"
              label="E-mail"
              required
            />

            <v-select
              v-model="user.role"
              :items="items"
              :rules="[(v) => !!v || 'Role is required']"
              label="Role"
              required
            />

            <v-checkbox
              v-if="isUpdate"
              v-model="password"
              label="Do you want to reset the password"
              required
            />

            <v-combobox
              v-model="allowedDomains"
              :items="domains"
              :search-input.sync="search"
              hide-selected
              label="Add allowed domains"
              multiple
              persistent-hint
              small-chips
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-form>
        </div>
        <v-card-actions class="justify-end">
          <v-btn v-if="isUpdate" text color="error" @click="tapDelete">
            Delete
          </v-btn>
          <v-spacer />
          <v-btn :disabled="!valid" text color="primary" @click="tapUpdate">
            {{ isUpdate ? "Save" : "Add" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["value", "isUpdate"],
  computed: {
    name() {
      return this.isUpdate ? "Edit" : "Add";
    }
  },
  data: () => ({
    dialog: false,
    valid: true,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    items: ["member", "admin"],
    password: false,
    user: {},
    domains: ["smarthome", "JPM", "chitchat", "ExpMeExpressions"],
    allowedDomains: [],
    search: null
  }),
  methods: {
    ...mapActions("users", ["addUser", "deleteUser", "updateUser"]),
    tapDelete() {
      this.deleteUser(this.user.username);
    },
    tapUpdate() {
      const payload = { ...this.user };
      if (this.allowedDomains.length > 0) {
        payload.options = { allowedDomains: this.allowedDomains };
      }
      else {
        payload.options = {}
      }
      if (this.isUpdate) {
        if (this.password) {
          payload.password = this.password;
        }
        this.updateUser(payload);
      } else {
        this.addUser(payload);
      }
      this.dialog = false;
    }
  },
  watch: {
    dialog(newValue) {
      if (!newValue) return;
      this.user = this.isUpdate ? this.value : { role: "member" };
      if (this.user.options.allowedDomains) {
        this.allowedDomains = this.user.options.allowedDomains;
      }
    }
  }
};
</script>
